import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import styled from 'styled-components';

import { makeFetchCancelReasons } from '../../../actions/Reservation';
import CalendarIcon from '../../atoms/CalendarIcon';
import MapIcon from '../../atoms/MapIcon';
import PersonIcon from '../../atoms/PersonIcon';
import PrimaryButton from '../../atoms/PrimaryButton';
import SecondaryButton from '../../atoms/SecondaryButton';
import FormSelectComponent from '../../molecules/reservation/FormSelectComponent';
import ReferTag, { EventTypeParam } from '../../organisms/reservation/ReferTag';
import myPageRoutePath from '../../pages/myPage/routePath';
import { ReservationType } from '../../pages/reservation/routePath';

class ReservationEditForm extends React.Component {
  static get propTypes() {
    return {
      id: PropTypes.string,
      type: PropTypes.string,
      handleSubmit: PropTypes.func,
      onSubmit: PropTypes.func,
      onCancel: PropTypes.func,
      fetchCancelReasons: PropTypes.func,
      reservationRefer: PropTypes.shape({
        id: PropTypes.string,
        eventTypeId: PropTypes.string,
        eventTypeName: PropTypes.string,
        date: PropTypes.string,
        name: PropTypes.string,
        isDisplaySeat: PropTypes.bool,
        seatAvailability: PropTypes.string,
        status: PropTypes.string,
        seat: PropTypes.number,
        isPaid: PropTypes.bool,
        price: PropTypes.number,
        hallName: PropTypes.string,
        defaultMessage: PropTypes.string,
        hallAddress: PropTypes.string,
        isAccess: PropTypes.bool,
        access: PropTypes.array,
        accessMapUrl: PropTypes.string,
        contents: PropTypes.string,
        chargeStaffName: PropTypes.string,
        belongings: PropTypes.string,
        clothes: PropTypes.string,
        isCancel: PropTypes.bool,
        tags: PropTypes.array,
      }),
      onPrevButtonClick: PropTypes.func,
      invalid: PropTypes.bool,
      submitting: PropTypes.bool,
      initialValues: PropTypes.any,
      formState: PropTypes.any,
      isError: PropTypes.bool,
      messages: PropTypes.arrayOf(PropTypes.string),
      options: PropTypes.shape({
        cancelReasons: PropTypes.array,
      }),
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      errorMessage: '',
    };
  }

  render() {
    const isSeatAvailability = () => {
      return (
        this.props.type === ReservationType.Event &&
        this.props.reservationRefer.isDisplaySeat
      );
    };

    const getTagList = () => {
      return this.props.reservationRefer.tags;
    };

    const getLocation = () => {
      const reservationRefer = this.props.reservationRefer;

      if (reservationRefer.hallName && reservationRefer.hallName.length > 0) {
        return reservationRefer.hallName;
      }

      if (
        reservationRefer.defaultMessage &&
        reservationRefer.defaultMessage.length > 0
      ) {
        return reservationRefer.defaultMessage;
      }

      return '';
    };

    const submitCancel = (values) => {
      // キャンセル理由が未選択の場合はエラーを表示
      if (!values.reasonId) {
        this.setState({
          isError: true,
          errorMessage: 'キャンセル理由を選択してください。',
        });
        return;
      }
      this.props.onCancel({
        ...values,
      });
    };

    return (
      <Form onSubmit={this.props.handleSubmit}>
        <section className="l-block">
          <div className="l-lead">
            <h2 className="c-lead">
              <span>キャンセル内容</span>
            </h2>
          </div>

          <article className="c-schedule">
            <div className="c-schedule_inner">
              <div className="c-schedule_tags">
                <ul>
                  <ReferTag
                    tagList={getTagList()}
                    interviewMethodType={
                      this.props.type === ReservationType.Interview
                        ? (this.props.reservationRefer || {}).eventTypeName
                        : null
                    }
                    eventType={
                      this.props.type === ReservationType.Event
                        ? EventTypeParam.Event
                        : this.props.type === ReservationType.Interview
                        ? EventTypeParam.Interview
                        : null
                    }
                    eventTypeName={
                      this.props.type === ReservationType.Event
                        ? this.props.reservationRefer.eventTypeName
                        : null
                    }
                  />
                </ul>
              </div>

              <h4 className="c-schedule_ttl">
                {this.props.reservationRefer.name}
              </h4>
              <div className="c-schedule_info">
                <ul>
                  <li>
                    <CalendarIcon />
                    {this.props.reservationRefer.date}
                  </li>
                  <li className="-location">
                    <MapIcon />
                    {getLocation()}
                  </li>

                  {isSeatAvailability() ? (
                    <li className="-numbers">
                      <PersonIcon />
                      <span className="u-disp-ib">
                        {this.props.reservationRefer.seat}/
                        {this.props.reservationRefer.seatAvailability}
                      </span>
                      名
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
              </div>
            </div>
          </article>
        </section>

        <section>
          <div className="l-lead">
            <h2 className="c-lead">
              <span>キャンセル理由【必須】</span>
            </h2>
          </div>

          {this.state.isError && (
            <DisplayErrorMessageSpan>
              {this.state.errorMessage}
            </DisplayErrorMessageSpan>
          )}
          <div>
            <dl className="c-form">
              <dd>
                <div className="c-form_pd -short">
                  <FormSelectComponent
                    options={this.props.options.cancelReasons}
                    name="reasonId"
                    hiddenFlg="false"
                  />
                </div>
              </dd>
            </dl>
          </div>

          <br />
        </section>

        <div className="l-btn">
          <PrimaryButton
            onClick={this.props.handleSubmit((values) => submitCancel(values))}
            reservationRefer={this.props.reservationRefer}
            isDisabled={this.props.reservationRefer.isCancel}
          >
            予約キャンセル
          </PrimaryButton>
          <SecondaryButton href={myPageRoutePath.top}>
            一覧に戻る
          </SecondaryButton>
        </div>
      </Form>
    );
  }
}

const DisplayErrorMessageSpan = styled.span`
  font-weight: 400;
  color: #d90d32;
`;

const reservationEditForm = reduxForm({
  // a unique name for the form
  form: 'reservationEditForm',
  enableReinitialize: true,
})(ReservationEditForm);

export default connect(
  (state) => {
    return {
      state,
      options: state.options,
    };
  },
  (dispatch) => {
    return {
      fetchCancelReasons() {
        dispatch(makeFetchCancelReasons());
      },
    };
  }
)(reservationEditForm);
